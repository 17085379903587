
<section class="banner container-fluid banner-image">
    <div class="overlay"></div>
    <div class="container ">
      <div class="row">
        <div class="col-md-8">
          <div class="col-title-wrapper">
  
            <h2 class="subtitle">
              KRAMIC POTTERY
            </h2>
            <h1 class="col-title">
              TIMBER NIGHFALL VASE ARTCERAMIC
            </h1>
            <h3 class="bgtitle">Madurai</h3>
          </div>
          <div class="content">
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elitlit tellus luctus nec ullamcorper pulvinar dapibus
              consectetur.</p>
          </div>
          <div class="additionaldetails">
            <ul>
              <li>MODEL
                <span></span> HM3321
              </li>
              <li>
                <span>
                  YEAR
                  2021
  
                </span>
              </li>
              <li>
                BRANCH
                Kramic
              </li>
            </ul>
            <a href="#" class="btn-gold">Contact Now</a>
          </div>
        </div>
        <div class="col-md-4 pad-15"></div>
      </div>
    </div>
  </section>
  
  <section class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-4 ">
          <div class="brand-wrapper bg-brand">
            <div class="col-title-wrapper other-section">
  
              <h2 class="subtitle">
                KRAMIC POTTERY
              </h2>
              <h1 class="col-title">
                TIMBER NIGHFALL VASE ARTCERAMIC
              </h1>
              <!-- <h3 class="bgtitle" >Madurai</h3> -->
            </div>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elituis turpis aliquam convallis dictum.
              </p>
              <div class="image-wrapper-right">
                <img width="100%"
                  src="assets/basket.png"
                  alt="pot">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="brand-wrapper bg-brand">
            <div class="col-title-wrapper other-section">
              <div class="image-wrapper-center">
                <img width="100%" height="450px"
                  src="assets/basketdark.png"
                  alt="pot">
              </div>
              <h2 class="subtitle">
                KRAMIC POTTERY
              </h2>
              <h1 class="col-title">
                TIMBER NIGHFALL VASE ARTCERAMIC
              </h1>
              <!-- <h3 class="bgtitle" >Madurai</h3> -->
            </div>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elituis turpis aliquam convallis dictum.
              </p>
  
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="brand-wrapper bg-brand">
            <div class="col-title-wrapper other-section">
  
              <h2 class="subtitle">
                KRAMIC POTTERY
              </h2>
              <h1 class="col-title">
                TIMBER NIGHFALL VASE ARTCERAMIC
              </h1>
              <!-- <h3 class="bgtitle" >Madurai</h3> -->
            </div>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elituis turpis aliquam convallis dictum.
              </p>
  
            </div>
            <div class="image-wrapper-center">
              <img width="100%" height="450px"
                src="assets/basketdark.png"
                alt="pot">
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="brand-wrapper bg-brand">
            <div class="col-title-wrapper other-section">
              <div class="image-wrapper-center">
                <img width="100%" height="450px"
                  src="assets/basket.png"
                  alt="pot">
              </div>
              <h2 class="subtitle">
                KRAMIC POTTERY
              </h2>
              <h1 class="col-title">
                TIMBER NIGHFALL VASE ARTCERAMIC
              </h1>
              <!-- <h3 class="bgtitle" >Madurai</h3> -->
            </div>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elituis turpis aliquam convallis dictum.
              </p>
  
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="brand-wrapper bg-brand">
            <div class="col-title-wrapper other-section">
  
              <h2 class="subtitle">
                KRAMIC POTTERY
              </h2>
              <h1 class="col-title">
                TIMBER NIGHFALL VASE ARTCERAMIC
              </h1>
              <!-- <h3 class="bgtitle" >Madurai</h3> -->
            </div>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elituis turpis aliquam convallis dictum.
              </p>
  
            </div>
            <div class="image-wrapper-center">
              <img width="100%" height="450px"
                src="assets/basketdark.png"
                alt="pot">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid no-pad">
  
    <div class="container">
      <div class="row">
        <div class="col-md-12">
  
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
              <img src="{{ slide.img }}" alt="" width="100%">
            </div>
          </ngx-slick-carousel>
          <!--     
          <button (click)="addSlide()">Add</button>
          <button (click)="removeSlide()">Remove</button>
          <button (click)="slickModal.slickGoTo(2)">slickGoto 2</button>
          <button (click)="slickModal.unslick()">unslick</button> -->
        </div>
      </div>
    </div>
  
  </section>
  
  <section class="container-fluid">
    <div class="col-title-wrapper text-center">
  
      <h2 class="subtitle">
        KRAMIC POTTERY
      </h2>
      <h1 class="col-title">
        TIMBER NIGHFALL VASE ARTCERAMIC
      </h1>
      <!-- <h3 class="bgtitle">Madurai</h3> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
            <span class="sale-badge">Sale</span>
            <img width="100%" height="200px" src="https://images.pexels.com/photos/4464482/pexels-photo-4464482.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
            <div class="col-title-wrapper">
              <h2 class>Mug</h2>
            </div>
            <p>lorem ipsum is good product</p>
            <a href="#" class="btn-gold">buy now</a>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
            <span class="sale-badge">Sale</span>
            <img width="100%" height="200px" src="https://images.pexels.com/photos/4219653/pexels-photo-4219653.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
            <div class="col-title-wrapper">
              <h2 class>Mug</h2>
            </div>
            <p>lorem ipsum is good product</p>
            <a href="#" class="btn-gold">buy now</a>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
            <span class="sale-badge">Sale</span>
            <img width="100%" height="200px" src="https://images.pexels.com/photos/6294406/pexels-photo-6294406.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
            <div class="col-title-wrapper">
              <h2 class>Mug</h2>
            </div>
            <p>lorem ipsum is good product</p>
            <a href="#" class="btn-gold">buy now</a>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
            <span class="sale-badge">Sale</span>
            <img width="100%" height="200px" src="https://images.pexels.com/photos/7674970/pexels-photo-7674970.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
            <!-- http://placehold.it/350x250/777777 -->
  
            <div class="col-title-wrapper">
              <h2 class>Mug</h2>
            </div>
            <p>lorem ipsum is good product</p>
            <a href="#" class="btn-gold">buy now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid bg-brand">
    <div class="container">
      <div class="row">
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
  
            <div class="col-title-wrapper">
              <div class="text-center counts"><span>12+</span></div>
              <h2 class>Experience</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
  
            <div class="col-title-wrapper">
              <div class="text-center counts"><span>10000+</span></div>
              <h2 class>Happy Customer</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
  
            <div class="col-title-wrapper">
              <div class="text-center counts"><span>50+</span></div>
              <h2 class>Products</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 pad-15">
          <div class="content-wrapper  text-center">
  
            <div class="col-title-wrapper">
              <div class="text-center counts"><span>12+</span></div>
              <h2 class>Experience</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-4 bg-brand-dark text-center d-flex">
          <div class="col-md-12 align-self-center col-title-wrapper ">
            <h2 class="subtitle">
              KRAMIC POTTERY
            </h2>
            <h1 class="col-title">
              CRAFTED PRODUCTS
            </h1>
            <p>* Lorem ipsum dolor consectetur adipiscing elit.</p>
            <a href="#" class="btn-gold">Contact Now</a>
          </div>
        </div>
  
        <div class="col-md-8 ">
          <div class="col-title-wrapper ">
  
            <h2 class="subtitle">
              KRAMIC POTTERY
            </h2>
            <h1 class="col-title">
              TIMBER NIGHFALL VASE ARTCERAMIC
            </h1>
            <!-- <h3 class="bgtitle">Madurai</h3> -->
          </div>
          <div class="content">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a feugiat purus. Duis turpis convallis
              dictum nisi. Curabitur vehicula tincidunt sapien velcac. Donec diam augue consequat sit amet metus
              acbibendum mattis massa. Duis mollis, ligula pretium consequat aliquet. s</p>
          </div>
          <div class="row">
            <div class="col-md-4 pad-15">
              <div class="content-wrapper  text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/4464482/pexels-photo-4464482.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
                <div class="col-title-wrapper">
                  <h2 class>Mug</h2>
                </div>
                <p>lorem ipsum is good product</p>
                <a href="#" class="btn-gold">buy now</a>
              </div>
            </div>
            <div class="col-md-4 pad-15">
              <div class="content-wrapper  text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/6294406/pexels-photo-6294406.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
                <div class="col-title-wrapper">
                  <h2 class>Mug</h2>
                </div>
                <p>lorem ipsum is good product</p>
                <a href="#" class="btn-gold">buy now</a>
              </div>
            </div>
            <div class="col-md-4 pad-15">
              <div class="content-wrapper  text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/7674970/pexels-photo-7674970.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
            
                <div class="col-title-wrapper">
                  <h2 class>Mug</h2>
                </div>
                <p>lorem ipsum is good product</p>
                <a href="#" class="btn-gold">buy now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-4  text-center d-flex">
          <div class="col-md-12 align-self-center col-title-wrapper ">
            <h2 class="subtitle">
              KRAMIC POTTERY
            </h2>
            <h1 class="col-title">
              CRAFTED PRODUCTS
            </h1>
            <p>* Lorem ipsum dolor consectetur adipiscing elit.</p>
          </div>
        </div>
  
        <div class="col-md-8 ">
          <div class="row d-flex">
            <div class="col-md-4 align-self-center pad-15 ">
              <div class="content-wrapper bg-brand text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/4464482/pexels-photo-4464482.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
                <div class="pad-30">
                    <div class="col-title-wrapper">
                    <h2 class>Mug</h2>
                    </div>
                    <p>lorem ipsum is good product</p>
                    <a href="#" class="btn-gold">buy now</a>
                </div>
              </div>
            </div>
            <div class="col-md-8 align-self-center pad-15">
              <div class="content-wrapper bg-brand text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/4219653/pexels-photo-4219653.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
                <div class="pad-30">
                    <div class="col-title-wrapper">
                    <h2 class>Mug</h2>
                    </div>
                    <p>lorem ipsum is good product <br>lorem buy as you wish</p>
                    <a href="#" class="btn-gold">buy now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="container-fluid">
    <div class="container">
      <div class="row">
  
        <div class="col-md-8 ">
          <div class="row d-flex">
  
            <div class="col-md-8 align-self-center pad-15">
              <div class="content-wrapper bg-brand  text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/6294406/pexels-photo-6294406.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
  
                <div class="pad-30">
                    <div class="col-title-wrapper">
                    <h2 class>Mug</h2>
                    </div>
                    <p>lorem ipsum is good product <br>lorem buy as you wish</p>
                    <a href="#" class="btn-gold">buy now</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center pad-15 ">
              <div class="content-wrapper bg-brand  text-center">
                <span class="sale-badge">Sale</span>
                <img width="100%" height="200px" src="https://images.pexels.com/photos/7674970/pexels-photo-7674970.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="">
            
                <div class="pad-30">
                    <div class="col-title-wrapper">
                    <h2 class>Mug</h2>
                    </div>
                    <p>lorem ipsum is good product</p>
                    <a href="#" class="btn-gold">buy now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4  text-center d-flex">
          <div class="col-md-12 align-self-center col-title-wrapper ">
            <h2 class="subtitle">
              KRAMIC POTTERY
            </h2>
            <h1 class="col-title">
              CRAFTED PRODUCTS
            </h1>
            <p>* Lorem ipsum dolor consectetur adipiscing elit.</p>
          </div>
        </div>
  
  
      </div>
    </div>
  </section>
  
  <section class="container-fluid bg-brand">
    <div class="container">
      <div class="row">
        <div class="col-md-12 align-self-center text-center col-title-wrapper ">
          <h2 class="subtitle">
            KRAMIC POTTERY
          </h2>
          <h1 class="col-title">
            CRAFTED PRODUCTS
          </h1>
          <p>* Lorem ipsum dolor consectetur adipiscing elit.</p>
          <a href="#" class="btn-gold">Contact Now</a>
        </div>
      </div>
    </div>
  </section>
  
  <section id="gallery">
    <div class="container text-center">
      <div class="col-title-wrapper">
  
        <h2 class="subtitle">
          KRAMIC POTTERY
        </h2>
        <h1 class="col-title">
          TIMBER NIGHFALL VASE ARTCERAMIC
        </h1>
      </div>
      <div id="image-gallery">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/500"><img src="https://unsplash.it/500" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/600"><img src="https://unsplash.it/600" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/700"><img src="https://unsplash.it/700" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/800"><img src="https://unsplash.it/800" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/900"><img src="https://unsplash.it/900" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/1000"><img src="https://unsplash.it/1000" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/1100"><img src="https://unsplash.it/1100" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
            <div class="img-wrapper">
              <a href="https://unsplash.it/1200"><img src="https://unsplash.it/1200" class="img-responsive"></a>
              <div class="img-overlay">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div><!-- End row -->
      </div><!-- End image gallery -->
      <a href="#" class="btn-gold">see more</a>
    </div><!-- End container --> 
  </section>
  <section class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62883.080080695705!2d78.08780403499948!3d9.917919583920817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c582b1189633%3A0xdc955b7264f63933!2sMadurai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1619730669035!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
      <div class="col-md-6">
        <div class="col-title-wrapper">
  
          <h2 class="subtitle">
            KRAMIC POTTERY
          </h2>
          <h1 class="col-title">
            CONTACT US
          </h1>
        </div>
        <div class="container-contact">
          <div class="wrap-contact">
      
            <form name="contact" class="contact-form validate-form" method="post" action= "contact-form-handler.php">
      
              <div class="wrap-input validate-input" data-validate="Please enter your name">
                <input class="input" type="text" name="name" placeholder="Full Name">
              </div>
      
              <div class="wrap-input validate-input" data-validate = "Please enter your email">
                <input class="input" type="text" name="email" placeholder="E-mail">
              </div>
      
              <div class="wrap-input validate-input" data-validate = "Please enter your message">
                <textarea class="input" type="text" name="message" placeholder="Your Message"></textarea>
              </div>
      
              <div class="container-contact-form-btn text-left">
                <button type="submit" class="btn-gold">
                  <span>Send</span>
                </button>
              </div>
      
              
      
            </form>
      
          </div>
        </div>
      </div>
    </div>
  </section>