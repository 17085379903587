<div id="image-gallery">
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/500"
          ><img src="https://unsplash.it/500" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/600"
          ><img src="https://unsplash.it/600" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/700"
          ><img src="https://unsplash.it/700" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/800"
          ><img src="https://unsplash.it/800" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/900"
          ><img src="https://unsplash.it/900" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/1000"
          ><img src="https://unsplash.it/1000" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/1100"
          ><img src="https://unsplash.it/1100" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
      <div class="img-wrapper">
        <a href="https://unsplash.it/1200"
          ><img src="https://unsplash.it/1200" class="img-responsive"
        /></a>
        <div class="img-overlay">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- End row -->
</div>
<!-- End image gallery -->
