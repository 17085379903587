<header class="main-nav">

  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-6">
        <img src="assets/logo-11.svg" height="60px" alt="logo">
      </div>
      <div class="col-md-9 col-sm-6  col-xs-6 text-right">
        <a href="#" class="">+91 9000000000</a>
        <a id="menu-open" href="#" class="btn-menu ">|&nbsp;|&nbsp;|</a>

      </div>
    </div>
  </div>

  <ul id="navigation" class="navigation" style="display:none">
    <li><a href="#" data='Home'><span></span>Home</a></li>
    <li><a href="#" data='About'><span></span>About</a></li>
    <li><a href="#" data='Services'><span></span>Services</a></li>
    <li><a href="#" data='Portfolio'><span></span>Portfolio</a></li>
    <li><a href="#" data='Contact'><span></span>Contact</a></li>
    <a id="menu-close" href="#" class="btn-menu ">X</a>
  </ul>

</header>

<router-outlet></router-outlet>
<footer class="bg-brand-dark">
  <div class="col-title-wrapper  text-center">

    <h1 class="col-title">
      BANANA ROPE MDU
    </h1>
  </div>
  <div class="container content-footer">
    <div class="row">
      <div class="col-md-12 pad-15">
        <p>uspendisse sit amet dignissim sem. Duis suscipit, nisi ut fermentum molestie, nibh velit hendrerit elit,uspendisse sit amet dignissim sem. Duis suscipit, nisi ut fermentum molestie, nibh velit hendrerit elit,

          uspendisse sit amet dignissim sem. Duis suscipit, nisi ut fermentum molestie, nibh velit hendrerit elit.

        </p>
        <p>no:3 lorem street ,Ipsum Nagar ,Madurai -600001 </p>
        <p>Phone : <a href="#" class="">+91 9000000000</a></p>
        
      </div>
      <div class="col-md-12">
        
        <a href="#" class="btn-gold">Facebook</a>
        <a href="#" class="btn-gold">Whatsapp</a>
        <a href="#" class="btn-gold">Twitter</a>
        
      </div>
    </div>
  </div>
</footer>